import React from "react";
import { HeartTwoTone } from '@ant-design/icons';

import '../static/css/animate.css'


class BcmFooter extends React.Component {

    render() {
    	return <div>
		    <div style={{display: "flex", height: "60px", justifyContent:'center',alignItems:'center', color:"grey", backgroundColor: "#EFEFEF"}}>
	            拔草猫 ~  <HeartTwoTone className="animated heartBeat" style={styles.heartIcon} twoToneColor="#eb2f96" />
	        </div>
	    </div>
    }
}

const styles = {
	heartIcon: {
		fontSize:'14px',
		marginLeft:'6px',
		animationDuration: '6s',
		animationDelay: '1s',
		animationIterationCount: 'infinite',
	}
};

export default BcmFooter;