import React from 'react';
import {Carousel, Button, Spin, Card, message} from 'antd';
import './DetailPage.css';
import Text from "antd/lib/typography/Text";
import {BcmFetch} from "../data/BcmFetch";
import BcmFooter from "../widgets/BcmFooter";
import BcmHeader from "../widgets/BcmHeader";
import BasePage from "../widgets/BasePage";
import queryString from "query-string";
import PicsPage from "../pics/PicsPage";
import BcmLogger from "../data/BcmLogger";


let autoCopy = false;
class DetailPage extends BasePage {
    constructor(props){
        super(props);
        let showQuan = (props.location && props.location.state);
        autoCopy = showQuan;
        this.state = {
            mall: props.match.params.mall,
            item_id: props.match.params.item_id,
            quan_id: new URLSearchParams(this.props.location.search).get("quan_id"),
            show_quan: showQuan ? props.location.state.show_quan : 0,
            result: null,
            loading: true,
            show_pics: false,
        }
    }
    render() {
        // console.log(' ------ item_id=' + this.props.match.params.item_id);
        // console.log(' ------ quan_id=' + this.props.match.params.quan_id);
        // console.log(' ------ shwo=' + this.props.match.params.show);
        var content_layout = <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', height: '600px'}}><Spin tip="努力加载中..." /></div>;
        if(this.state.result != null){
            const item_data = this.state.result.data;
            const img_elements = [];
            item_data.images.forEach((item) => {
                img_elements.push(
                    <div key={item}>
                        <div
                            onClick={()=>this.clickPics()}
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <img
                                height={180}
                                alt="logo"
                                src={item}/>
                        </div>
                    </div>
                )
            });

            try {
                if (autoCopy && item_data.quan_type !== 1) {
                    autoCopy = false;
                    setTimeout(() => this.clickCopy(), 1000);
                }
            }catch (e) {
                BcmLogger.log( e );
            }

            content_layout =
                <div className="Detail-layout2">
                    <Carousel style={{backgroundColor: "#EFEFEF"}} autoplay>
                        {img_elements}
                    </Carousel>
                    <div className="content-bg" style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{padding: '10dp', fontWeight: 'bold', fontSize: '16px'}}>{item_data.title}</div>
                        <div className="Detail-shop">
                            <div>{item_data.shop_type}</div>
                            <div style={{margin: '0 6px'}}>|</div>
                            <div>{item_data.shop_name}</div>
                        </div>
                    </div>
                    <div className="Detail-layout-price">
                        <Text className="Detail-price" style={{justifyContent: 'center'}}>¥{item_data.price}</Text>
                        <div className="Detail-price-re">¥{item_data.price_re}</div>
                        <div className="Detail-price-end">券后价</div>
                        <div className="Detail-price-end">¥{item_data.price_end}</div>
                        <div style={{flex: 10}}/>
                        { item_data.quan === "0" ?
                            <Button type="link" disabled>暂无优惠券</Button> :
                            <Button type="dashed" danger>-{item_data.quan}</Button>
                        }
                    </div>
                    <div className="content-bg" style={{paddingBottom: "60px"}}>
                        <div style={{padding: '16px 0'}}>{item_data.content}</div>
                        <div style={{display:'flex', flexDirection: 'row', fontSiz: '12px'}}>
                            { item_data.quan_type === 1 ?
                                <div className="Detail-quan-tip">点击或复制以下链接，打开<b>{item_data.shop_type}</b>:</div>:
                                <div className="Detail-quan-tip">复制以下文字，打开<b>{item_data.shop_type}</b>:</div>
                            }
                            <div style={{flex: 10}}/>
                            {item_data.quan_code ? <Button type='link' onClick={() => this.clickCopy()}>点击复制</Button> : <div/>}
                        </div>
                        <Card style={{padding: '12px',border: '1px solid #ffccc7'}}>
                            <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                            { this.props.match.params.mall === 'jd' ?
                                     item_data.quan_url ?
                                        <a id="copyContent"
                                           rel="noopener noreferrer"
                                           onClick={()=>this.clickQuanLink()}
                                           href={item_data.quan_url.replace(/(\\)/g, '')} target='_blank'>
                                            {item_data.quan_url.replace(/(\\)/g, '')}
                                        </a> :
                                        <Button loading={this.state.loading && this.state.show_quan === 1}
                                            type="link" onClick={() => this.clickShowQuan()}>点击显示优惠链接</Button>
                                     :
                                        item_data.quan_code ?
                                            <p id="copyContent">{item_data.quan_code}</p> :
                                            <Button loading={this.state.loading && this.state.show_quan === 1}
                                                    type="link" onClick={() => this.clickShowQuan()}>点击获取优惠码</Button>


                            }
                            </div>
                        </Card>
                    </div>
                    <PicsPage
                        onClose={()=>this.setState({show_pics: false})}
                        images={item_data.images} show={this.state.show_pics} />
                </div>;
        }
        return <div className="Detail-layout">
            <div>
                <BcmHeader />
                {content_layout}
                <BcmFooter/>
            </div>
        </div>
    }
    clickPics(){
        this.setState({show_pics: true})
    }
    clickShowQuan(){
        this.setState({
            show_quan: 1
        });
        BcmFetch.analysis_click('quan', queryString.stringify({
            mall: this.state.mall, item_id: this.state.item_id, quan_id: this.state.quan_id}));
        this.reGetDetailNet()
    }
    clickCopy() {
        const copyEle = document.getElementById('copyContent'); // 获取要复制的节点
        const range = document.createRange(); // 创造range
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
        range.selectNode(copyEle); // 选中需要复制的节点
        window.getSelection().addRange(range); // 执行选中元素
        const copyStatus = document.execCommand("Copy"); // 执行copy操作
        // 对成功与否定进行提示
        if (copyStatus) {
            message.success('复制成功');
        } else {
            message.warn('复制失败');
        }
        window.getSelection().removeAllRanges(); //清除页面中已有的selection

        BcmFetch.analysis_click('copy', queryString.stringify({
            mall: this.state.mall, item_id: this.state.item_id, quan_id: this.state.quan_id}));
    }
    clickQuanLink(){
        BcmFetch.analysis_click('link', queryString.stringify({
            mall: this.state.mall, item_id: this.state.item_id, quan_id: this.state.quan_id}));
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     this.getDetailNet(
    //         nextProps.match.params.mall,
    //         nextProps.match.params.item_id,
    //         new URLSearchParams(nextProps.location.search).get("quan_id"),
    //         nextProps.location.state.show_quan
    //     )
    // }
    componentDidMount() {
        super.componentDidMount();
        this.getDetailNet()
    }
    reGetDetailNet(){
        const _this = this;
        setTimeout( function () {
            _this.getDetailNet();
        },200)
    }
    getDetailNet() {
        //console.log(" ------ searchNet id=" + item_id + " mall=" + mall + " quan=" + quan_id + " show=" +  show);
        this.setState({loading: true});
        const {mall, item_id, quan_id, show_quan} = this.state;
        const _this = this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
        BcmFetch.getDetail(mall, item_id, show_quan, quan_id )
              .then(function (response) {
                  // handle success
                  //console.log(response);
                  //console.log('code ' + response.data.code + ' - ' + response.data.data);
                  if (response.data.code === 0) {
                        _this.setState({
                            result: response.data
                        });
                  } else {
                      console.log('result false ');
                      message.warn("获取失败(" + response.data.code + ")")
                  }
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
                      message.warn("加载失败")
              })
              .then(function () {
                  // always executed
                  _this.setState({ loading: false})
              });
  }

}


export default DetailPage;