import React from 'react';
import {Tabs, message, Spin, BackTop} from 'antd';
import {BcmFetch} from "../data/BcmFetch";
import BcmFooter from "../widgets/BcmFooter";
import BcmHeader from "../widgets/BcmHeader";
import BasePage from "../widgets/BasePage";
import BcmLogger from "../data/BcmLogger";
import RecommendList from "./RecommendList";
import './RecommendPage.css'
// import Icon from '@ant-design/icons';

const {TabPane} = Tabs;

class RecommendPage extends BasePage {
    constructor(props){
        super(props);
        this.state={
        	loading_status: 0,
        	cate_panes : [
		      { title: '抢购中', key: '1' },],
            cate_data: {}
        }
    }
    render() {
        const loading = this.state.loading_status === 0;
        const cate_panes = this.state.cate_panes;
        const cate_data = this.state.cate_data;
        return <div style={{backgroundColor: '#EFEFEF'}}>
                <BcmHeader title="" customTitle={<img src='./res/today_title.png' />}/>
                <Spin tip="努力加载中..." spinning={loading}>
                    <div style={{backgroundColor: '#FFFFFF'}}>
                        <Tabs defaultActiveKey="1">
                                {cate_panes.map(pane => (
                                    <TabPane key={pane.key} tab={
                                            <span style={{margin: '0 6px'}}> {pane.title} </span>}>
                                        <RecommendList data_list={cate_data[pane.key]}/>
                                    </TabPane>
                                ))}
                        </Tabs>
                    </div>
                </Spin>
                <BcmFooter/>
                <BackTop />
            </div>;
    }
    componentDidMount() {
        super.componentDidMount();
        this.queryTodayRecommend()
    }
    queryTodayRecommend() {
        const _this = this;
        BcmFetch.frRecommendToday(1, 200)
              .then(function (response) {
                  if (response.data.code === 0) {
                        _this.parseResponse(_this, response.data.data);
                  } else {
                        BcmLogger.log('queryTodayRecommend false ');
                        _this.setState({
                            loading_status: 2
                        });
                        if(response.data.msg){
                            message.warn(response.data.msg)
                        }
                  }
              })
              .catch(function (error) {
                  // handle error
                    console.log(error);
                    _this.setState({
                        loading_status: 2
                    })
              })
              .then(function () {
                  // always executed
                  //   _this.setState({
                  //       loading_status: 2
                  //   })
              });
    }

    parseResponse(_this, res_data){
        let cate_panes = [];
        let cate_data = {};
        if(res_data && res_data.length > 0){
            let cates = [];
            res_data.forEach((item)=>{
                const type_name = item.type_name;
                if (cates.indexOf(type_name) < 0){
                    cates.push(type_name)
                }
                if (cate_data[type_name] == null){
                    cate_data[type_name] = []
                }
                cate_data[type_name].push(item)
            });
            cates.forEach((cate) =>{
                cate_panes.push({title: cate, key: cate})
            });
        }
        _this.setState({
            cate_panes: cate_panes,
            cate_data: cate_data,
            loading_status: 1,
        });
    }

}



export default RecommendPage;