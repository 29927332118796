import React from 'react';
import {Input, message} from 'antd';
import { List, Typography ,Button, Skeleton, Radio, Checkbox, Spin, BackTop} from 'antd';
import './SearchListPage.css';
import {BcmFetch} from "../data/BcmFetch";
import BcmFooter from "../widgets/BcmFooter";
// import {Link} from "react-router-dom";
import BcmHeader from "../widgets/BcmHeader";
import BasePage from "../widgets/BasePage";
import queryString from "query-string";
// import { StickyContainer, Sticky } from 'react-sticky';
const { Search } = Input;
const { Text } = Typography;

const MALL_TB = 'tb';
const MALL_JD = 'jd';
const PER_PAGE = 8;

let page = 1;
let page_last = false;

class SearchListPage extends BasePage {
    constructor(props){
        super(props);

        let mall = new URLSearchParams(this.props.location.search).get("mall");
		if(!mall){
            const propsState = props.location.state;
		    mall = (propsState && propsState.mall) ? propsState.mall : MALL_TB;
        }
        this.state={
            loadingStatus: 0,
            loadingMore: false,
            keyword: new URLSearchParams(this.props.location.search).get("q"),
            orderBy: 'auto',
            hasQuanOnly: false,
            mall: mall,
            results: [],
            //results: [{title: 'title_2'},{title: 'title_2'},{title: 'title_3'}]
        }
    }
    render() {
        //console.log(" ------ render SearchListPage " + this.props.match.params.keyword);
        var emptyStr = '没有找到相关商品哦～';
        if(this.state.loadingStatus === 0){
            emptyStr = '加载中 ...';
        }
        const {loadingStatus, results} = this.state;
        const loadMore = loadingStatus !== 0 && results && results.length !== 0 ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#F6F6F6',
                            textAlign: 'center',
                            height: 60,
                            lineHeight: '32px'}}>
                            { page_last ? <div style={{color: '#B6B6B6'}}>没有更多了哦</div> :
                                <Button type='link' loading={this.state.loadingMore} onClick={() => this.searchNetMore()}>加载更多</Button>
                            }
                        </div>) : null;
        return <div style={{backgroundColor: '#EFEFEF'}}>
                <BcmHeader />
                {this.BcmSearchHeader(this.state.keyword)}
                <Spin tip="努力加载中..." spinning={loadingStatus === 0}>
                    <List
                         style={{minHeight: '200px'}}
                        // header={<div style={{height: '0px'}}> </div>}
                        //footer={<BcmFooter/>}
                        loadMore={loadMore}
                        locale={{emptyText: emptyStr}}
                        bordered={false}
                        dataSource={results}
                        renderItem={item => (
                            this.renderItemView(item)
                    )}/>
                </Spin>
                <BcmFooter/>
                <BackTop />
            </div>;
    }
    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         loadingStatus: 0,
    //         keyword: nextProps.match.params.keyword,
    //     });
    //     this.reSearchNet()
    // }

    componentDidMount() {
        super.componentDidMount();
        this.searchNetInit()
    }

    renderItemView(item){
        //console.log(" ------ render Item " + this.props.history);
        return <div style={{position:"relative", marginTop:"2px"}} key={item.item_id + '_' + item.quan_id}>

                <Skeleton loading={this.state.loadingStatus === 0} avatar active>
                    <div className="Item-layout" onClick={() => this.clickDetailBtn(item.item_id, item.quan_id, 0)}>
                        <div style={{minWidth:'100px'}}>
                            <img
                                width={100}
                                style={{margin:'auto'}}
                                alt="logo"
                                src={item.image} />
                        </div>
                        <div className="Item-layout-content">
                            <Text level={5}>{item.title}</Text>
                            <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'space-between', height: '80px' }}>
                                <div style={{ display:'flex', flexDirection: 'row', alignItems: 'center', marginTop: '6px' }}>
                                    <div className="Item-layout-price">¥{item.price}</div>
                                    <div className="Item-layout-price-after">券后</div>
                                    <div className="Item-layout-price-end">¥{item.price_end}</div>
                                    <div className="Item-layout-price-master">{item.price_master}</div>
                                </div>
                                <div style={{fontSize: '12px'}}> 销量 {item.sale}</div>
                                <div style={{fontSize: '12px'}}> {item.shop_type} | {item.shop_name}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        { item.quan && item.quan !== '0'?
                            <Button
                                className="Item-layout-button"
                                type="dashed" size="small"
                                danger
                                onClick={() => this.clickDetailBtn(item.item_id, item.quan_id, 1)}>领券 -¥{item.quan}</Button> :
                            <Button
                                className="Item-layout-button"
                                type="dashed"   size="small"
                                onClick={() => this.clickDetailBtn(item.item_id, item.quan_id, 1)}>直接购买</Button>
                        }
                    </div>
                </Skeleton>
            </div>
    }


    onMallChange = e => {
        this.setState({
            loadingStatus: 0,
            mall: e.target.value,
        });
        this.reSearchNet()
    };
    onOrderByChange = e => {
        //console.log('radio checked', e.target.value);
        this.setState({
            loadingStatus: 0,
            orderBy: e.target.value,
        });
        this.reSearchNet()
    };
    onHasQuanOnlyChange(e) {
        //console.log('hasQuanOnly checked ', e.target.checked);
        this.setState({
            loadingStatus: 0,
            hasQuanOnly: e.target.checked,
        });
        this.reSearchNet()
    };
    BcmSearchHeader(keyword){
        return <div
            style={{ backgroundColor:'white', padding: '20px', display: 'flex', flexDirection:'column', alignItems: 'center' }}>
            <Search
                placeholder=""
                defaultValue={keyword}
                onSearch={value => this.clickSearchBtn(value)} enterButton />
            <Radio.Group
                onChange={this.onMallChange}  value={this.state.mall}
                defaultValue="t" buttonStyle="outline" size="small" style={{width: '100%', marginTop: '10px'}}>
                <Radio.Button style={{width: '50%', textAlign:'center'}} value={MALL_TB}>淘宝/天猫</Radio.Button>
                <Radio.Button style={{width: '50%', textAlign:'center'}} value={MALL_JD}>京东</Radio.Button>
            </Radio.Group>
            <div style={{display: 'flex', width: '100%', flexDirection:'row',marginTop: '10px', justifyContent: 'center', alignItems:'center'}}>
                <div style={{marginRight: '6px', fontSize: '12px'}}>排序:</div>
                <Radio.Group onChange={this.onOrderByChange} value={this.state.orderBy}>
                    <Radio style={{fontSize: '12px'}} value={"auto"}>综合</Radio>
                    <Radio style={{fontSize: '12px'}} value={"price"}>价格</Radio>
                    <Radio style={{fontSize: '12px'}} value={"sale"}>销量</Radio>
                </Radio.Group>
                <div style={{flex: '10', textAlign:'right', color: 'grey'}}/>
                <Checkbox style={{fontSize: '12px', padding: '2px'}} onChange={this.onHasQuanOnlyChange.bind(this)}>仅看有券</Checkbox>
            </div>
        </div>
    }

    clickDetailBtn(item_id, quan_id, show_quan) {
        //console.log(" click detail btn quan_id =" + quan_id + "  item_id=" + item_id);
        this.props.history.push({
            pathname: '/detail/' + this.state.mall +  "/"  + item_id + "/",
            search: '?quan_id=' + encodeURIComponent(quan_id),
            //query: {'quan_id' : quan_id},
            state: {'show_quan': show_quan} });
    }
    clickSearchBtn(keyword){
        if(!keyword){
            message.warn('请输入关键词');
            return;
        }
        this.setState({keyword: keyword});
        this.reSearchNet();
        //this.props.history.push({pathname: "/search", search: queryString.stringify({ q: keyword })});
    }

    reSearchNet() {
        this.setState({loadingStatus: 0 });
        const _this = this;
        setTimeout( function () {
            _this.searchNetInit();
        }, 200);
    }
    searchNetInit(){
        page = 1;
        page_last = false;
        this.searchNet(res => {
            this.setState({
                results: res.data
            })
        })
    }
    searchNetMore(){
        page = page + 1;
        this.setState({ loadingMore: true});
        this.searchNet(res => {
            let newData = res.data;
            if(!newData || newData < PER_PAGE){
               page_last = true;
                page = page - 1;
            }
            if(!newData || newData.length <= 0){
                newData = []
            }
            this.setState({
                loadingMore: false,
                results: this.state.results.concat( newData )
            })
        })
    }
    searchNet = callback => {
        //console.log( ' searchNet keyword=' + this.state.keyword + "  mall=" + this.state.mall);
        //console.log( ' searchNet state  orderBy=' + this.state.orderBy + "  hasQuanOnly=" + this.state.hasQuanOnly);
        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
        BcmFetch.searchNet(this.state.keyword, this.state.orderBy, this.state.hasQuanOnly, page, this.state.mall)
              .then(function (response) {
                  // handle success
                  //console.log(response);
                  //console.log('is_login ' + response.data.code + ' - ' + response.data.data);
                  if (response.data.code === 0) {
                      //console.log('is_login  itemtitle = ' + response.data.data[0].itemtitle);
                        _this.setState({
                            loadingStatus: 1,
                        })
                  } else {
                      console.log('is_login false ');
                        _this.setState({
                            loadingStatus: 2
                        })
                  }
                  callback(response.data)
              })
              .catch(function (error) {
                  // handle error
                    console.log(error);
                    _this.setState({
                        loadingStatus: 2
                    })
              })
              .then(function () {
                  // always executed
                  //   _this.setState({
                  //       loadingStatus: 2
                  //   })
              });
    }

}



export default SearchListPage;