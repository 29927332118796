import React from "react";
import {
  CloseCircleOutlined,
} from '@ant-design/icons';
import {Carousel, Modal} from "antd";
import './PicsPage.css'
import {withRouter} from "react-router-dom";
import BcmLogger from "../data/BcmLogger";


class PicsPage extends React.Component {

	constructor(props){
        super(props);
        this.state={
        	images: props.images ? props.images : [],
	        show: props.show,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
        	images: nextProps.images ? nextProps.images : [],
	        show: nextProps.show,
        });
    }

    clickClose(){
		this.setState({ show: false});
	    if(this.props.onClose){
	    	this.props.onClose();
	    }
    }

    carouselChange(current){
		BcmLogger.log("  carouselChange = " + current)
    }

	render() {
		const images = this.state.images;
		const show = this.state.show && this.state.show > 0;
		let images_div = [];
		images.forEach((item) => (
					images_div.push(<div key={item}><div style={{display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',}}>
						<img src={item} alt='img'
			                     style={{maxHeight: '400px', height:'100%', minWidth: '300px',
				                     maxWidth:'400px', width:'100%',
				                     padding: '0 0 0 0'}}/></div></div>)
				));
		return <div className="pics_wrap" style={{
					display: show ? 'flex': 'none',
					position: show ? 'fixed' : 'none', zIndex: show? 900 : 0}} >
			{/*<div onClick={()=>this.clickClose()}*/}
			{/*	style={{*/}
			{/*	position: 'fixed', zIndex: 990,*/}
			{/*	height: '100%', width: '100%', top: '0', bottom: '0', left: '0', right: '0'}} />*/}
			<Modal visible={show} title={null} footer={null} destroyOnClose={true}
                    bodyStyle={{padding: '0',backgroundColor: '#00000000'}}
					onCancel={()=> this.clickClose()}>
			<Carousel
				className="pic_preview" dotPosition='top'
		        afterChange={(current) => this.carouselChange(current)}
				style={{height: '360px', width:'100%', zIndex:'999'}}>
				{images_div}
			</Carousel>
			</Modal>
			{/*<CloseCircleOutlined style={{ marginTop: '80px',color:'white', fontsize:'18px', zIndex:'999'}}*/}
			{/*                     onClick={()=>this.clickClose()}/>*/}
		</div>
	}

}


// export default PicsPage;
export default withRouter(PicsPage);