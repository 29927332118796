
import Base64 from "base-64";

class BcmData {
    static isLogin(){
        const token = this.getToken();
        return token !== null && token.length > 10;
    }
    static getToken(){
        return localStorage.getItem('token');
    }
    static setToken(token){
        if(token == null){
            localStorage.removeItem("token");
            return;
        }
        localStorage.setItem('token', token);
    }

    static getUserNickLocal(){
        let user = this.getUserInfoLocal();
        if(user != null && user.length > 10){
            try {
                return JSON.parse(user).nick;
            }catch (e) {
                return null;
            }
        }
        return null;
    }
    static setUserInfoLocal(user_info){
        if(user_info == null){
            localStorage.removeItem("user_info");
            return;
        }
        localStorage.setItem('user_info', Base64.encode( unescape(encodeURIComponent(user_info)) ));
    }
    static getUserInfoLocal(){
        const str = localStorage.getItem('user_info');
        if(str){
            return decodeURIComponent(escape(Base64.decode(str)));
        }
        return null;
    }
}

export {
    BcmData
}