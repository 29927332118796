import React from 'react';
import {Descriptions, Spin, Avatar, Button, Divider, Popconfirm, message} from 'antd';
import {BcmFetch} from "../data/BcmFetch";
import {Link} from "react-router-dom";
import './UserPage.css'
import BcmHeader from "../widgets/BcmHeader";
import BcmFooter from "../widgets/BcmFooter";
import BasePage from "../widgets/BasePage";
import {BcmData} from "../data/BcmData";
import BcmLogger from "../data/BcmLogger";


class UserPage extends BasePage {
    constructor(props){
        super(props);
        this.state={
            loading: false,
            login_status: 0,
            user_info: null,
            user_info_ex: null
        }
    }
    render() {
        let content;
        BcmLogger.log( 'login_status = ' + this.state.login_status);
        const user_info = this.state.user_info;
        const user_info_ex = this.state.user_info_ex;
        if(this.state.login_status === 0){
            content = <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', height: '200px', width: '100%',}}><Spin tip="努力加载中..."/></div>;
        }else if(this.state.login_status > 1 || user_info == null){
            content = <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', height: '200px', width: '100%',}}>
                <div>请先<Link to={"/login"}>登录</Link></div>
            </div>
        } else {
            const avatarColor = user_info.gender === 1 ? '#2233EE' : '#EE5566';
            content = <div>
                <Descriptions title="" column={2}>
                    <Descriptions.Item label="" span={2}>
                        <div style={{display: 'flex', justifyContent: 'center', height: '100px', alignItems:'center'}}>
                            <Avatar style={{ backgroundColor: avatarColor, verticalAlign: 'middle' }} size="large">
                                {user_info.nick}
                            </Avatar>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="昵称">{user_info.nick}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{user_info.phone}</Descriptions.Item>
                    <Descriptions.Item label="E-mail">{user_info.email}</Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="我的" column={2}>
                    <Descriptions.Item label="积分">
                        <Link to={"/user/point"}>{user_info_ex? user_info_ex.points: 0}</Link>
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="管理操作" layout="horizontal" column={1}>
                    { user_info.master && user_info.master > 2000 ?
                    <Descriptions.Item label="后台管理">
                        <Button onClick={() => this.onClickBackend()}>进入后台</Button>
                    </Descriptions.Item> : <div/>
                    }
                    <Descriptions.Item label="个人管理">
                        <Popconfirm
                            title="确定要退出登录吗?"
                            onConfirm={this.onClickLogout}
                            okText="确定"
                            cancelText="再想想">
                            <Button>退出登录</Button>
                        </Popconfirm>
                    </Descriptions.Item>
                </Descriptions>
            </div>;
        }
        return <div className="User_layout_main">
            <BcmHeader />
                <div className="User_layout_content">
                    {content}
                </div>
            <BcmFooter/>
        </div>
    }

    componentDidMount() {
        super.componentDidMount();
        this.myUserInfo();
        this.myUserInfoEx();
    }

    onClickBackend(){
        window.open("http://admin.bacaomao.com");
    }
    onClickLogout(){
        BcmData.setToken(null);
        BcmData.setUserInfoLocal( null );
        //this.props.history.push('/');
        window.location.reload();
    }

    myUserInfo(){
          const _this = this;
          _this.setState({
              loading: true
          });
          BcmFetch.userInfo().then(function (response) {
                    if (response.data.code === 0) {
                        //BcmLogger.log( response.data );
                        BcmData.setUserInfoLocal( JSON.stringify(response.data.data) );
                        _this.setState({
                            loading: false,
                            login_status: 1,
                            user_info: response.data.data
                        });
                        return;
                    } else if(response.data.code === 1){
                        BcmData.setUserInfoLocal( null );
                    }
                    let msg = response.data.msg ? response.data.msg : '请求用户信息错误';
                    message.warn(msg );

                    BcmLogger.log('user info false ');
                    _this.setState({
                        loading: false,
                        login_status: 2
                    })
                })
                .catch(function (error) {
                    // handle error
                    BcmLogger.log(error);
                    message.warn('请求用户信息出错');
                    _this.setState({
                        loading: false,
                        login_status: 2
                    })
                })
                .then(function () {
                    // always executed
                });
    }

    myUserInfoEx(){
          const _this = this;
        BcmFetch.userInfoEx().then(function (response) {
            if (response.data.code === 0) {
                    _this.setState({
                        user_info_ex: response.data.data
                    });
                }
        }).catch(function (error) {
                // handle error
                console.log(error);
        })
        .then(function () {
            // always executed
        });
    }
}


export default UserPage;