import React from "react";
import {PageHeader} from "antd";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";


class BcmHeader extends React.Component {

    render() {
    	let title = "拔草猫";
    	if(this.props.title != null){
    		title = this.props.title;
	    }
    	let customTitle = null;
    	if(this.props.customTitle != null) {
	        customTitle = this.props.customTitle;
	    }
    	return <div style={{position: 'relative'}}>
                <PageHeader
                    style={styles.bcmPageHeader}
                    onBack={() => this.onClickBack()}
                    title={title}
                    subTitle=" "/>
                <Link style={styles.rightButton} to={"/"}>首页</Link>
		        <div style={{...styles.customTitle, ...{display: customTitle? 'flex':'none'}}}>{customTitle}</div>
	    </div>
    }

    onClickBack(){
    	//console.log(" click back " + window.history.length);
    	if(window.history.length <= 0){
    		this.props.history.push('/');
	    }else {
		    window.history.back();
	    }
    }

}

const styles = {
	bcmPageHeader: {
	    border: '1px solid rgb(235, 237, 240)',
	    backgroundColor: 'white',
	},
	rightButton: {
		position: 'absolute',
		top: '20px',
		right: '20px'

	},
	customTitle: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`
	}
};

export default withRouter(BcmHeader);

// export default BcmHeader;