import React from 'react';
import {Tag, Modal} from 'antd';
import { List, Typography ,Button, Skeleton} from 'antd';
import RecommendDialog from "./RecommendDialog";
import PicsPage from "../pics/PicsPage";
import './RecommendPage.css'
const { Text, Title } = Typography;


class RecommendList extends React.Component {
    constructor(props){
        super(props);
        this.state={
        	data_list: props.data_list,
            item_click: null,
            show_click_dialog: 0,
            show_pics: false,
        }
    }
    render() {
        const data_list = this.state.data_list ? this.state.data_list : [];
        let item_click_images = [];
        if(this.state.item_click && this.state.item_click.goods && this.state.item_click.goods.images){
            item_click_images = this.state.item_click.goods.images;
        }
        return <div style={{backgroundColor: '#EFEFEF'}}>
                <List
                    style={{minHeight: '200px'}}
                    locale={{emptyText: '暂无内容'}}
                    bordered={false}
                    dataSource={data_list}
                    renderItem={item => (
                        this.renderItemView(item)
                    )}/>
                <Modal visible={this.state.show_click_dialog > 0}
						title={null} footer={null} destroyOnClose={true}
                        bodyStyle={{padding: '0'}}
                        width={360} centered={true}
						onCancel={()=> this.setState({show_click_dialog: 0})} >
                    <RecommendDialog
                        auto_copy={this.state.show_click_dialog > 1}
                        recommend_item={this.state.item_click}/>
                </Modal>
                <PicsPage
                    onClose={()=>this.setState({show_pics: false})}
                    images={item_click_images} show={this.state.show_pics} />
            </div>;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: nextProps.data_list,
        });
    }

    renderItemView(item){
        const recommend_item = item;
        const goods_item = recommend_item.goods;
        let img_url = '';
	            if( goods_item.images && goods_item.images.length > 0){
	                img_url = goods_item.images[0];
		        }
        return <div style={{position:"relative", marginTop:"2px"}} key={goods_item.item_id + '_' + goods_item.quan_id}>

                <Skeleton loading={this.state.loadingStatus === 0} avatar active>
                    <div className="Reco-layout">
                        <div style={{width:'120px', height:'120px'}}>
                            <img
                                width={120} height={120}
                                style={{margin:'auto'}}
                                alt="logo"
                                src={img_url}  onClick={()=>this.clickShowPics(item)}/>
                            <div style={{position:'absolute',padding: '0px 2px', top:'16px', left:'16px',
                                color: '#F0F0F0EB', fontSize: '10px',
                                background:'#666666BB'}}>
                                {goods_item.images.length}图</div>
                        </div>
                        <div className="Reco-layout-content" onClick={() => this.clickShowBtn(item)}>
                            <Typography.Paragraph
                                ellipsis={{rows:2, expandable: false}}
                                level={5} style={{color: 'black', marginBottom: '0px'}}>
                                {recommend_item.title}
                            </Typography.Paragraph>
                            <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'space-around', height: '80px' }}>
                                <Typography.Paragraph
                                    ellipsis={{rows:1, expandable: false}}
                                    style={{fontSize: '16px', color: 'orange', marginBottom: '0px'}}>
                                    {recommend_item.phrase}
                                </Typography.Paragraph>
                                <div className="Reco-layout-price">日常价¥{goods_item.price}</div>
                                <div style={{ display:'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div className="Reco-layout-price-after">券后¥</div>
                                    <div className="Reco-layout-price-end">{goods_item.price_end}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button
                            className="Reco-layout-button"
                            shape="round"
                            type="primary" size="middle" danger
                            onClick={() => this.clickGoBtn(item)}>领券购买</Button>
                    </div>
                </Skeleton>
            </div>
    }


    clickGoBtn(item) {
        this.setState({
            show_click_dialog: 2,
            item_click: item
        })
    }
    clickShowBtn(item){
        this.setState({
            show_click_dialog: 1,
            item_click: item
        })
    }
    clickShowPics(item){
        this.setState({
            show_pics: 1,
            item_click: item
        })
    }



}



export default RecommendList;