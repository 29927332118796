import BasePage from "../widgets/BasePage";
import BcmHeader from "../widgets/BcmHeader";
import BcmFooter from "../widgets/BcmFooter";
import React from "react";
import './PointPage.css'
import {Button, List, message, Modal, Spin} from "antd";
import {Link} from "react-router-dom";
import {BcmFetch} from "../data/BcmFetch";
import OrderCodePage from "./OrderCodePage";


let page = 1;
let page_last = false;
const PER_PAGE = 10;
class PointPage extends BasePage {
    constructor(props){
        super(props);
        this.state={
            login_status: 0,
            points: null,
            loadingMore: false,
            showOrderCode: false,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.queryPointsRefresh();
    }

    render() {
        const login_status = this.state.login_status;
    	const points = this.state.points;
        let content;
        if(this.state.login_status === 0){
            content = <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', height: '200px', width: '100%',}}><Spin tip="努力加载中..."/></div>;
        }else if(this.state.login_status > 1 || points == null){
            content = <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', height: '200px', width: '100%',}}>
                <div>请先<Link to={"/login"}>登录</Link></div>
            </div>
        } else {

            const loadMore = login_status !== 0 && points.list && points.list.length !== 0 ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#F6F6F6',
                            textAlign: 'center',
                            height: 60,
                            lineHeight: '32px'}}>
                            { page_last ? <div style={{color: '#B6B6B6'}}>没有更多了哦</div> :
                                <Button type='link' loading={this.state.loadingMore} onClick={() => this.queryPointsMore()}>加载更多</Button>
                            }
                        </div>) : null;
	        content = <div>
                <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
		            <div style={{marginRight:'10px'}}>当前积分:{points.sum ? points.sum : 0}</div>
                    <a onClick={()=>this.showCheckOrderCode(true)}>录入单号</a>
                </div>
                <div style={{margin:'20px 0 10px 0'}}>积分记录:</div>
                <List
                     style={{minHeight: '200px'}}
                    // header={<div style={{height: '0px'}}> </div>}
                    //footer={<BcmFooter/>}
                    loadMore={loadMore}
                    locale={{emptyText: '还没有积分，请检查是否录入过正确的单号'}}
                    bordered={false}
                    dataSource={points.list}
                    renderItem={item => (
                        this.renderItemView(item)
                )}/>
                <Modal
                    title="订单号"
                    onCancel={()=>this.showCheckOrderCode(false)}
                    visible={this.state.showOrderCode}
                    footer={null}>
                    <OrderCodePage />
                </Modal>
	        </div>
        }
        return <div className="User_layout_main">
            <BcmHeader />
                <div className="User_layout_content">
                    {content}
                </div>
            <BcmFooter/>
        </div>
    }
    renderItemView(item){
        let amountDiv = <div style={{color: 'red'}}>+{item.amount}</div>;
        if (item.amount < 0) {
            amountDiv = <div style={{color: 'black'}}>-{item.amount}</div>;
        }
        return <div style={{display:'flex', flexDirection: 'row',
                    alignItems: 'center',justifyContent: 'space-between', height: '60px'}}>
            <div>{item.source}</div>
            <div style={{flex:1, margin: '0 10px'}}>{amountDiv}</div>
            <div>{item.point_time}</div>
        </div>
    }

    showCheckOrderCode(show){
        this.setState({showOrderCode: show})
    }

    queryPointsRefresh() {
        page_last = false;
        page = 1;
        this.queryPoints()
    }
    queryPointsMore() {
        page = page + 1;
        this.queryPoints()
    }
    queryPoints(){
        const _this = this;
        BcmFetch.userPoints(page, PER_PAGE).then(function(response){
            if(response && response.data && response.data.code === 0 && response.data.data){
                let results ={
                    sum: response.data.data.sum ? response.data.data.sum : 0,
                    list: _this.state.points ? _this.state.points.list : []};
                if(response.data.data && response.data.data.list && response.data.data.list.length < PER_PAGE){
                    page_last = true;
                }
                results.list = page > 1 ? results.list.concat(response.data.data.list) : response.data.data.list;
                // BcmLogger.log(JSON.stringify(results));
                _this.setState({
                    login_status: 1,
                    points: results,
                })
            }else {
                _this.setState({
                    login_status: 2
                })
            }
        }).catch(function (error) {
                // handle error
                console.log(error);
                message.error("加载失败")
        })
        .then(function () {
            // always executed
        });
    }
}


export default PointPage;