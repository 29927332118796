import React from 'react';
import { Form, Input, Button, message, Alert} from 'antd';
import Text from "antd/lib/typography/Text";
import './LoginPage.css'
import BcmHeader from "../widgets/BcmHeader";
import BcmFooter from "../widgets/BcmFooter";
import BasePage from "../widgets/BasePage";
import {BcmFetch} from "../data/BcmFetch";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
};
class RegisterPage extends BasePage {
    constructor(props){
        super(props);
        this.state = {
            loading_status: 1, // 0 正在注册,  1 正常,  2 注册失败,   -1 注册错误
            error_message: ''
        }
    }
    render() {
        const {loading_status, error_message} = this.state;
        let alert_div = '';
        if(loading_status === 0){
            alert_div = <Alert message="正在注册..." type="info" style={{marginBottom: '20px'}}/>
        }else if(loading_status > 1){
            alert_div = <Alert message={'注册失败:' + error_message} type="error" showIcon style={{marginBottom: '10px'}}/>
        }else if(loading_status < 0){
            alert_div = <Alert message="注册出错，请检查网络或联系管理员" type="error" showIcon style={{marginBottom: '10px'}}/>
        }
        return <div style={{backgroundColor:'white'}}>
            <div className="Login-layout-main">
                <BcmHeader />
                <div className="Login-layout-content">
                    <Text className="Login-logo">注册拔草猫优惠团</Text>
                    <div style={{display:"flex", width: '60%', padding: '0, 60px', justifyContent: 'center', alignItems: 'center'}}>
                        <Form style={{width: '100%'}}
                            {...layout}
                            name="basic"
                            onFinish={this.onInputFinish.bind(this)}
                            onFinishFailed={this.onInputFinishFailed}>
                            <Form.Item
                                label="手机号"
                                name="phone"
                                rules={[{ required: true, message: '请输入手机号!' }]} >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="昵称"
                                name="nick"
                                rules={[{ required: true, message: '请取一个昵称' }]} >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="密码"
                                name="pwd"
                                rules={[{ required: true, message: '请输入密码!' }]} >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="pwd2"
                                rules={[{ required: true, message: '请输入确认密码!' }]} >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="订单号"
                                name="order_code"
                                rules={[{ required: true, message: '请输入购买过的天猫/淘宝/京东订单号' }]} >
                                <Input
                                    placeholder="用本站优惠码购买的天猫/淘宝/京东订单号" />
                            </Form.Item>

                            { alert_div }

                            <Form.Item {...tailLayout}>
                                <Button
                                    type="primary"
                                    loading={loading_status === 0}
                                    htmlType="submit">注册</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <BcmFooter/>
            </div>
        </div>
    }

    onInputFinish(values) {
        //console.log('Success:', values);
	    if(values.pwd !== values.pwd2){
	    	message.warn("两次密码输入不一致");
	    	return
	    }
        this.setState({
            loading_status: 0
        });
        this.userRegister(values.phone, values.pwd, values.order_code, values.nick)
    }
    onInputFinishFailed(errorInfo){
        console.log('Failed:', errorInfo);
    };

    userRegister(phone, pwd, order_code, nick){
        const _this = this;
    	BcmFetch.userRegister(phone, pwd, order_code, nick).then(function (response) {
    		let loading_status = 0;
    		let error_msg = '';
		    if(response.data && response.data.code === 0){
		    	loading_status = 1;
		    	message.success("注册成功，请登录");
			    _this.props.history.push('/login');
		    }else {
		    	loading_status = 2;
		    	error_msg = response.data.msg;
		    }
		    _this.setState({loading_status: loading_status, error_message: error_msg})
	    }).catch(function (error) {
		    _this.setState({loading_status: -1, error_message: "注册错误"})
	    })
    }
}

export default RegisterPage;