import BasePage from "../widgets/BasePage";
import React from "react";
import {withRouter} from "react-router-dom";
import { Form, Input, Button, Select, Alert } from 'antd';
import {BcmFetch} from "../data/BcmFetch";
const { Option } = Select;


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
class OrderCodePage extends BasePage {

    constructor(props){
        super(props);
        this.state = {
            loading_1: 1, // 0 正在加载,  1 正常,  2 失败,   -1 错误
            loading_2: 1, // 0 正在加载,  1 正常,  2 失败,   -1 错误
	        result_1: null,
            message_1: '',
	        message_2: '',
        }
    }

	componentDidMount() {
		super.componentDidMount();
		this.queryUserInfo();
	}

	render() {
    	const result_1 = this.state.result_1;
    	const alert_type = this.state.loading_2 === 0 ? 'success' : 'warning';
		return <div>
			<div style={{marginBottom: '20px'}}>
				{ result_1 && result_1.ali_order ?
					<div>
						<div>已录入天猫淘宝订单号：<b>{result_1.ali_order}</b></div>
						<div>天猫/淘宝订单号只需录入一次，自动识别，如重新提交会覆盖为新的订单号</div>
					</div>:
					<div>请录入天猫/淘宝订单号，天猫/淘宝订单号只需录入一次，自动识别</div>
				}
			</div>
			<Form
				{...layout}
				name="basic"
				initialValues={{ remember: true }}
				onFinish={this.onInputFinish.bind(this)}
				onFinishFailed={this.onFinishFailed.bind(this)}>
				<Form.Item style={{width: '100%'}}>
				    <Input.Group compact style={{width: '100%'}}>
						<Form.Item
							name={'mall'}
							noStyle
					        hasFeedback
					        rules={[{ required: true, message: '请选择商城!' }]}>
							<Select placeholder="选择商城">
								<Option value="B">天猫</Option>
								<Option value="C">淘宝</Option>
								<Option value="J">京东</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name={'order_code'}
							noStyle
							rules={[{ required: true, message: '请输入订单号' }]} >
							<Input style={{ width: '60%' }} placeholder="" />
						</Form.Item>
					</Input.Group>
				</Form.Item>
				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit" loading={this.state.loading_2 === 0}>提交</Button>
				</Form.Item>
			</Form>
			{this.state.message_2 && this.state.message_2.length > 0 ?
				<Alert message={this.state.message_2} type={alert_type} /> : <div/>
			}
		</div>
	}

    onInputFinish(values) {
    	const _this = this;
    	this.setState({loading_2: 0, message_2: null});
		BcmFetch.submitOrderCode(values.mall, values.order_code).then(function (response) {
			let loading_2 = 0;
			if(response.data && response.data.code === 0){
				loading_2 = 1;
			}else {
				loading_2 = 2;
			}
			_this.setState({loading_2: loading_2,message_2: response.data.msg})
		}).catch(function (error) {
			// handle error
			console.log(error);
			_this.setState({
				loading_2: -1,
				message_2: '加载错误',
			});
		})

	}
	onFinishFailed(){

	}

	queryUserInfo(){
    	const _this = this;
		BcmFetch.userInfoEx().then(function (response) {
			if(response.data && response.data.code === 0){
				_this.setState({result_1: response.data.data})
			}
		})
	}
}

export default withRouter(OrderCodePage);