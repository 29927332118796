import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import HomePage from "./home/HomePage";
import SearchListPage from "./search/SearchListPage";
import DetailPage from "./detail/DetailPage";
import LoginPage from "./login/LoginPage";
import UserPage from "./user/UserPage";
import PointPage from "./point/PointPage";
import RegisterPage from "./login/RegisterPage";
import RecommendPage from "./recommend/RecommendPage";


function App() {
  return (
    <Router>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path='/register' component={RegisterPage} />
      <Route exact path="/user" component={UserPage} />
        <Route exact path="/user/point" component={PointPage} />
      <Route exact path="/search" component={SearchListPage} />
      <Route exact path="/detail/:mall/:item_id" component={DetailPage} />
      <Route exact path="/today" component={RecommendPage} />
      <Route exact path="/" component={HomePage} />
    </Router>
  );

}


export default App;
// export default withRouter(App);

