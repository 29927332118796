import React from 'react';
import {Avatar, Input, Select, message} from 'antd';
import Text from "antd/lib/typography/Text";
import './HomePage.css'
import {Link} from "react-router-dom";
import {BcmData} from "../data/BcmData";
import {BcmFetch} from "../data/BcmFetch";
import queryString from "query-string";
import BasePage from "../widgets/BasePage";
import {
  FireFilled,
} from '@ant-design/icons';
const { Search } = Input;
const { Option } = Select;

class HomePage extends BasePage {
    constructor(props){
        super(props);
        this.state = {
            login_status: 0,
            mall: "tb"
        }
    }

    componentDidMount() {
        super.componentDidMount();
        const userNick = BcmData.getUserNickLocal();
        if(userNick == null && BcmData.getToken() != null){
            this.getUser();
        }
    }

    render() {
        const userNick = BcmData.getUserNickLocal();
        const selectBefore = (
          <Select defaultValue="tb" className="select-before" onChange={(value) => this.handleMallChange(value)}>
            <Option value="tb">淘宝</Option>
            <Option value="jd">京东</Option>
          </Select>
        );
        return <div style={{ height: "100%", backgroundColor:'white'}}>
            <div className="Home-layout-main">
                <div className="Home-login">
                    {userNick ?
                        <Link to={"/user"}>
                            <Avatar style={{ backgroundColor:'#EE6666', verticalAlign: 'middle', margin: '0 6px' }} size="small">
                                  {userNick}
                            </Avatar>
                            {userNick}
                        </Link>:
                        <div>
                            <Link to={"/login"}>登录</Link>
                        </div>
                    }
                </div>
                <Text className="Home-logo">拔草猫优惠团</Text>
                <Search
	                style={{maxWidth: '600px'}}
                    placeholder="输入商品关键词"
                    enterButton="查优惠"
                    allowClear
                    addonBefore={selectBefore}
                    onSearch={value => this.clickSearch(value)} />
            </div>
            <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                <Link to={"/today"}><FireFilled style={{color: 'red'}} /> 今日优惠精选!! 抢购中... </Link>
            </div>
        </div>
    }
    handleMallChange(value){
      console.log(`selected ${value}`);
      this.setState({mall: value})
    }
    clickSearch(keyword){
        if(!keyword){
            message.warn('请输入关键词');
            return;
        }
        this.props.history.push({
            pathname: '/search',
            search: queryString.stringify({q:keyword, mall: this.state.mall}),
            // state: {'mall': 'jd'}
        });
    }

    getUser(){
        const _this = this;
        BcmFetch.userInfo().then(function (response) {
                    if (response.data.code === 0) {
                        console.log( response.data );
                        BcmData.setUserInfoLocal( JSON.stringify(response.data.data) );
                        _this.setState({
                            login_status: 1,
                        })
                    } else {

                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                });
    }

}


export default HomePage;