import React from 'react';
import {Card, Alert, Button, message, Typography} from 'antd';
import {BcmFetch} from "../data/BcmFetch";
import BcmLogger from "../data/BcmLogger";
import './RecommendPage.css'
import queryString from "query-string";

class RecommendDialog extends React.Component {
    constructor(props){
        super(props);
        this.state={
            recommend_item: props.recommend_item,
            auto_copy: props.auto_copy,
            copy_success: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({recommend_item: nextProps.recommend_item})
    }

    componentDidMount() {
        if(this.state.auto_copy) {
            this.clickCopy();
        }
    }

    render(){
        const recommend_item = this.state.recommend_item;
        const goods_item = recommend_item.goods;
        let img_url = '';
	            if( goods_item.images && goods_item.images.length > 0){
	                img_url = goods_item.images[0];
		        }
        return <div style={{backgroundColor: '#EFEFEF'}}>
                    <Card hoverable style={{ width: '100%', minHeight: '300px'}} bordered
                          cover={<img alt="img" src={img_url} style={{height: '300px'}} />}
                            bodyStyle={{padding: '0'}}>
                        <div style={{margin: '10px'}}>
                        <Typography.Paragraph
                            ellipsis={{rows:1, expandable: false}}
                            style={{color: 'black', marginBottom: '2px'}}>
                            {recommend_item.title}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                            ellipsis={{rows:3, expandable: false}}
                            style={{fontSize: '12px',color: 'grey', marginBottom: '2px'}}>
                            {goods_item.content}
                        </Typography.Paragraph>
                        { goods_item.quan_type === 1 ?
                            <a id="copyContent"
                               style={{display:"flex", justifyContent:'center', alignItems:'center',
                                   height: '40px', backgroundColor: '#fff2f0', border: '1px solid #ffccc7'
                               }}
                               rel="noopener noreferrer"
                               onClick={()=>this.clickQuanLink()}
                               href={goods_item.quan_url.replace(/(\\)/g, '')} target='_blank'>
                                {goods_item.quan_url.replace(/(\\)/g, '')}
                            </a> :
                            <div onClick={() => this.clickCopy()}>
                                <Alert message={goods_item.quan_code} type="error" />
                                <div id="copyContent" style={{fontSize:'0', width: '0', height: '0'}}>{goods_item.quan_code}</div>
                            </div>
                        }
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            {goods_item.quan_type === 1 ?
                                <Button
                                    type='primary'
                                    style={{width: '90%', marginBottom: '10px'}}
                                    onClick={() => this.clickQuanLink()}>
                                    <a rel="noopener noreferrer"
                                       href={goods_item.quan_url.replace(/(\\)/g, '')}
                                       target='_blank'>点击去领券购买</a>
                                </Button> :
                                <Button
                                    type={this.state.copy_success ? 'dashed' : 'primary'}
                                    style={{width: '90%', marginBottom: '10px'}}
                                    onClick={() => this.clickCopy()}>
                                    {this.state.copy_success ? "已复制,打开淘宝/天猫APP即可进入" : "点击复制"}
                                </Button>
                            }
                        </div>
                    </Card>
            </div>;
    }

    clickQuanLink(){
        const goods_item = this.state.recommend_item.goods;
        BcmFetch.analysis_click('recommend_link', queryString.stringify({
            mall: goods_item.shop_type, item_id: goods_item.item_id, quan_id: goods_item.quan_id}));
    }

    clickCopy() {
        const copyEle = document.getElementById('copyContent');
        const range = document.createRange(); // 创造range
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
        range.selectNode(copyEle); // 选中需要复制的节点
        window.getSelection().addRange(range); // 执行选中元素
        const copyStatus = document.execCommand("Copy"); // 执行copy操作
        // 对成功与否定进行提示
        if (copyStatus) {
            message.success('复制成功');
        } else {
            message.warn('复制失败');
        }
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
        this.setState({copy_success: true} );


        const recommend_item = this.state.recommend_item;
        const goods_item = recommend_item.goods;
        BcmFetch.analysis_click('recommend_copy', queryString.stringify({
            mall: goods_item.shop_type, item_id: goods_item.item_id, quan_id: goods_item.quan_id}));
    }
}




export default RecommendDialog;