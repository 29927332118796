import React from 'react';
import { Form, Input, Button, message, Alert} from 'antd';
import Text from "antd/lib/typography/Text";
import './LoginPage.css'
import {BcmFetch} from "../data/BcmFetch";
import {BcmData} from "../data/BcmData";
import BcmHeader from "../widgets/BcmHeader";
import BcmFooter from "../widgets/BcmFooter";
import BasePage from "../widgets/BasePage";
// import BcmLogger from "../data/BcmLogger";
import {Link} from "react-router-dom";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
};
class LoginPage extends BasePage {
    constructor(props){
        super(props);
        this.state = {
            login_status: 1, // 0 正在登录,  1 正常,  2 登录失败,   -1 登录错误
            error_message: ''
        }
    }
    render() {
        const {login_status, error_message} = this.state;
        let alert_div = '';
        if(login_status === 0){
            alert_div = <Alert message="正在登录..." type="info" style={{marginBottom: '20px'}}/>
        }else if(login_status > 1){
            alert_div = <Alert message={'登录失败:' + error_message} type="error" showIcon style={{marginBottom: '10px'}}/>
        }else if(login_status < 0){
            alert_div = <Alert message="登录出错，请检查网络或联系管理员" type="error" showIcon style={{marginBottom: '10px'}}/>
        }
        return <div style={{backgroundColor:'white'}}>
            <div className="Login-layout-main">
                <BcmHeader />
                <div className="Login-layout-content">
                    <Text className="Login-logo">拔草猫优惠团</Text>
                    <div style={{display:"flex", width: '60%', padding: '0, 60px', justifyContent: 'center', alignItems: 'center'}}>
                        <Form style={{width: '100%'}}
                            {...layout}
                            name="basic"
                            onFinish={this.onInputFinish.bind(this)}
                            onFinishFailed={this.onInputFinishFailed}>
                            <Form.Item
                                label="手机号"
                                name="phone"
                                rules={[{ required: true, message: '请输入手机号!' }]} >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="密码"
                                name="pwd"
                                rules={[{ required: true, message: '请输入密码!' }]} >
                                <Input.Password />
                            </Form.Item>

                            { alert_div }

                            <Form.Item {...tailLayout}>
                                <Button
                                    type="primary"
                                    loading={login_status === 0}
                                    htmlType="submit">登录</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Link style={{marginTop: '20px'}} to={'/register'}>没有账号？注册一个</Link>
                </div>
                <BcmFooter/>
            </div>
        </div>
    }
    onInputFinish(values) {
        //console.log('Success:', values);
        const _this = this;
        this.setState({
            login_status: 0
        });
        setTimeout(()=>{
            _this.postLogin(values);
        }, 200)
    }
    postLogin(values) {
        const _this = this;
        BcmFetch.userLogin(values.phone, values.pwd).then(function (response) {
                  // handle success
                  //console.log(response);
                    let status = 1;
                    let err_message = '';
                  if (response.data.code === 0 && response.data.data.token) {
                      BcmData.setToken(response.data.data.token);
                      _this.props.history.push('/');
                      status = 1;
                  } else {
                      //console.log('result false ');
                      err_message = response.data.msg ? response.data.msg : '未知错误';
                      message.error(err_message);
                      status = 2;
                  }
                  _this.setState({
                        login_status: status,
                        error_message: err_message
                  });
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
                  _this.setState({
                        login_status: -1
                  });
              })
              .then(function () {
                  // always executed
              });
    };
    onInputFinishFailed(errorInfo){
        console.log('Failed:', errorInfo);
    };
}


export default LoginPage;
