import React from "react";
import {BcmFetch} from "../data/BcmFetch";
import queryString from "query-string";

class BasePage extends React.Component {

	_isMounted = false;


	setState(state, callback) {
		if(!this._isMounted){
			console.error(" page _isMounted = false ;; " + JSON.stringify(state));
			return;
		}
		super.setState(state, callback);
	}


	componentDidMount() {
		this._isMounted = true;
		console.log(this.props.location.pathname + ' search=' + queryString.extract(this.props.location.search));
		BcmFetch.analysis_view(this.props.location.pathname, queryString.extract(this.props.location.search))
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
}


export default BasePage;